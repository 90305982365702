<template>
  <div class="grid-container">
    <div v-if="code === 404 || componentsCode === 404">
      <h1>Page not found</h1>
      Return to
      <a :href="`/${projectPath}/`"> Home page </a>
    </div>
    <div v-if="code === 500 || componentsCode === 500">
      <h1>Sorry, something went wrong</h1>
      Return to
      <a :href="`/${projectPath}/`"> Home page </a>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'StatusCode',
  data() {
    return {
      projectPath: window.location.pathname.split('/')[1],
    }
  },
  computed: {
    ...mapState({
      code: state => state.settings.pageCode,
      location: state => state.settings.pageLocation,
      componentsCode: state => state.settings.componentsCode,
    }),
  },
  mounted() {
    setTimeout(() => {
      window.prerenderReady = true
    }, 0)
    if (this.code === 301 || this.code === 302) {
      if (navigator.userAgent.indexOf('prerender') !== -1) {
        return
      }
      window.location.href = this.location
    }
  },
  metaInfo() {
    return {
      title: this.code,
      meta: [
        {
          hid: 'prerender-status-code',
          name: 'prerender-status-code',
          content: this.code,
        },
        this.location !== ''
          ? {
              hid: 'prerender-header',
              name: 'prerender-header',
              content: `Location: ${this.location}`,
            }
          : {},
      ],
    }
  },
}
</script>
